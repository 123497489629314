(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);throw new Error("Cannot find module '"+o+"'")}var f=n[o]={exports:{}};t[o][0].call(f.exports,function(e){var n=t[o][1][e];return s(n?n:e)},f,f.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
jQuery(document).ready(function () {

  jQuery( ".slider-main" ).each(function() {
    jQuery(this).slick({
      slidesToShow: 2,
      row: 2,
      slidesPerRow: 1,
      arrows: false,
      vertical: true,
      autoplay: true,
      verticalSwiping: true,
      centerMode: false,
      centerPadding: '30px'
    });
  });

  // testimonial slider
  jQuery('.entrepreneurs-slider').slick({
    dots: true,
    speed: 500,
    autoplaySpeed:5000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnFocus: false, 
    pauseOnHover: false,
    responsive: [{
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  });

  // search icon
  jQuery(".search .nav-link").click(function () {
    jQuery(".wrapper-simple").find('input[type=text]').toggle(function () {
      jQuery(".wrapper-simple").find('input[type=text]').animate({
        'display': 'inline-block',
      });
    });

  });
  

  jQuery('.nav-item .nav-link.has-submenu').click(function () {
      if (jQuery(this).siblings('.sub-menu').hasClass('opened')) {
        jQuery('.sub-menu').removeClass('opened');
      }
      else {
        jQuery('.sub-menu').removeClass('opened');
        jQuery(this).siblings('.sub-menu').addClass('opened');
        
      }
  });

  jQuery('.nav-item .sub-menu .close-icon').click(function () {
      // if (jQuery(this).parent('.sub-menu').hasClass('opened')) {
      //   jQuery('.sub-menu').removeClass('opened');
      // }
      // else {
      //   jQuery('.sub-menu').removeClass('opened');
      //   jQuery(this).parent('.sub-menu').addClass('opened');
        
      // }
    jQuery('.sub-menu').removeClass('opened');
  });

  jQuery('.nav-item.dropdown .dropdown-toggle').click(function () {
    jQuery('.navbar-nav .nav-item').each(function () {
      jQuery(this).find('.sub-menu').removeClass('opened');
    });
  });

  jQuery('.nav-item.dropdown .dropdown-toggle').click(function () {
      jQuery(this).parents('.navbar').find('.navbar-collapse').removeClass('show');
  });

  var txt;
  txt = jQuery(".header").outerHeight();
  jQuery(".header .navbar .nav-item .sub-menu").css({
    top: txt
  });

});

jQuery(window).scroll(function () {
  var scroll = $(window).scrollTop();
  if (scroll >= 100) {
    $("header").addClass('header_fixed');
  } else {
    $("header").removeClass('header_fixed');

  }
});
},{}]},{},[1])